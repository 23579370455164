<template>
  <div class="max-width">
    <v-row align="center">
      <v-col cols="12" md="5">
        <div
          class="product-card cursor-pointer mx-10"
          @click.prevent="$router.push('/product-order/' + product.id)"
        >
          <div class="d-flex align-center text-center pt-3">
            <img
              class="object-contain mx-auto"
              height="220px"
              width="80%"
              :src="product.image"
              alt=""
            />
          </div>
          <div class="pa-3 d-flex align-center justify-space-between px-5">
            <span class="d-block black--text font-28 font-500">{{
              product.title
            }}</span>
            <span class="d-block font-35 font-900 purple--text"
              >{{ product.price }}$</span
            >
          </div>
          <div v-if="product.website" class="product-card__website text-center">
            <img
              class="object-contain mx-auto"
              height="62px"
              width="200px"
              :src="product.website[0].icon"
              alt=""
            />
          </div>
        </div>
      </v-col>
      <v-col cols="6" md="7">
        <v-form
          v-model="valid"
          @submit.prevent="handleSubmit"
          ref="productform"
        >
          <v-col cols="10" class="mx-auto">
            <base-date
              v-model="obj.shipment_date"
              @handleDate="obj.shipment_date = $event"
              :placeholder="$t('date')"
            ></base-date>
          </v-col>
          <v-col
            cols="10"
            class="mx-auto cursor-pointer"
          >
            <base-select
              :placeholder="$t('category')"
              :items="categories"
              v-model="obj.category_id"
            ></base-select>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="locationDialog"
      width="90%"
      max-width="1000px"
      content-class="white pa-3 location-dialog"
    >
      <location-dialog></location-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LocationDialog from "../../../components/dialogs/LocationDialog.vue";

export default {
  components: { LocationDialog },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    valid: false,
    obj: {
      shipment_date: "",
      latitude: 102,
      longitude: 130,
      address: "",
      city: "",
      country: "",
    },
    cities: [],
    markers: [{ lng: 0, lat: 0 }],
    location: { lat: 32.21212, lng: 32.121212 },
    goToAddress: "",

    locationDialog: false,
  }),
  computed: {
    ...mapGetters(["countries","categories","loading_countries_categories"]),
  },
  methods: {
    getAddressData(addressData) {
      if (addressData) {
        this.location.lat = addressData.latitude;
        this.location.long = addressData.longitude;
      }
    },
    async addLocation(event) {
      this.markers[0].lng = event.latLng.lng();
      this.markers[0].lat = event.latLng.lat();
      let response = await this.$store.dispatch("setMapMarker", event);
      if (response.country) {
        this.obj.address = response.fullAddress;
        this.obj.country = response.country;
        this.obj.city = response.city;
        this.keyUpdated = !this.keyUpdated;
      } else {
        this.obj.address = "";
        this.obj.country = "";
        this.obj.city = "";
        this.keyUpdated = !this.keyUpdated;
      }
    },
    async handleSubmit() {
      let formData = new FormData();
      formData.append("product_id", this.product.id);
      formData.append("category_id", this.product.category_id);
      formData.append("shipment_date", this.obj.shipment_date);
      formData.append("country", this.obj.country);
      formData.append("city", this.obj.city);
      formData.append("address", this.obj.address);
      formData.append("latitude", this.obj.latitude);
      formData.append("longitude", this.obj.longitude);

      let { data } = await this.$axios.post("buyForMe", formData);

      this.$store.dispatch("showSnack", {
        text: data.message,
        color: data.success ? "success" : "error",
      });
      if (data.success) {
        this.$refs.productform.reset();
      }
    },
  },
  created(){
    // this.$store.dispatch("fetchCountries")
  }

};
</script>

<style lang="scss">
.product-card {
  box-shadow: 0px 4px 20px 0px #00000017;

  &__website {
    border-top: 1px solid #66656533;
  }
}
.location-dialog {
  box-shadow: 0px 4px 54px 0px #00000038;
  height: 800px;
}
</style>
